<template>
  <div
    class="d-flex content-center min-vh-100 w-100 change-password-page"
    :style="background ? `background-image: url('${background.url}')` : ''"
  >
    <CModal
      :title="modal.title"
      :color="modal.color"
      :show.sync="modal.show"
      :centered="true"
      class="text-left"
    >
      {{ modal.message }}
    </CModal>

    <CContainer>
      <CRow class="d-flex content-center">
        <CCol sm="12" md="12" lg="6">
          <CCardGroup>
            <CCard class="p-4" accent-color="info">
              <CCardBody>
                <div class="d-inline-block w-100 mb-4">
                  <div
                    class="card-image d-flex justify-content-center w-100 logo-box"
                  >
                    <img
                      v-if="coloredLogo"
                      :src="coloredLogo.url"
                      :alt="coloredLogo.title"
                    />
                    <img
                      v-else
                      src="@/assets/img/logo.svg"
                      alt="ow-logo-black"
                    />
                  </div>
                  <small
                    v-if="!expired"
                    class="text-muted d-inline-block w-100 mt-3"
                  >
                    Entre com a sua nova senha para recuperar o seu acesso
                  </small>
                  <span v-else class="text-expired d-inline-block w-100 mt-3">
                    Sua senha expirou, por favor insira uma nova senha
                  </span>
                </div>
                <CForm class="text-left" @submit.prevent="send">
                  <CInput
                    v-model="form.password"
                    label="Nova Senha*"
                    placeholder="Nova senha"
                    type="password"
                    name="password"
                    autocomplete="false"
                    required
                    :disabled="processing"
                    @blur="checkConfirmationPassword"
                  >
                  </CInput>
                  <PasswordRequirements :password="form.password" />
                  <CInput
                    id="confirm_password"
                    v-model="form.password_confirmation"
                    label="Confirmação da senha*"
                    type="password"
                    placeholder="Confirmação de senha"
                    autocomplete="false"
                    :is-valid="passwordErrors.confirmation"
                    invalid-feedback="As senhas não conferem"
                    required
                    :disabled="processing"
                    @blur="checkConfirmationPassword"
                  >
                  </CInput>
                  <CRow class="align-items-center">
                    <CCol col="6" class="text-left">
                      <CButton
                        color="link"
                        type="button"
                        style="
                          padding-left: 0px !important;
                          padding-right: 0px !important;
                        "
                        @click="$router.push('/login')"
                      >
                        Voltar para login
                      </CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton
                        color="info"
                        class="px-4"
                        type="submit"
                        :disabled="processing"
                      >
                        {{ processing ? 'Enviando...' : 'Enviar' }}
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthService from '../../services/auth.service'
import PasswordRequirements from '@/components/ui/PasswordRequirements'
import { mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: 'Alterar Senha',
    titleTemplate: ' %s - Manager - Rodonaves Iveco'
  },

  components: {
    PasswordRequirements
  },

  data() {
    return {
      form: {
        password: '',
        password_confirmation: ''
      },
      modal: {
        show: false,
        title: '',
        message: ''
      },
      processing: false,
      expired: false,
      passwordErrors: {
        confirmation: null
      }
    }
  },

  computed: {
    ...mapGetters('settings', ['coloredLogo', 'background']),
    token() {
      return this.$route.params.token
    }
  },

  async created() {
    if (this.$route.path.includes('/expired')) {
      this.expired = true
    }
  },

  methods: {
    async send() {
      this.processing = true

      const data = {
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        token: this.token
      }

      const response = await AuthService.setNewPassword(data)

      this.processing = false

      if (response === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message:
            'Sua senha foi alterada com sucesso. Redirecionando para o login...',
          color: 'success'
        }

        setTimeout(() => {
          this.$router.push('/login')
        }, 3000)
      }

      if (response === 403) {
        this.modal = {
          show: true,
          title: 'Erro',
          message:
            'O token é invalido por favor gere um novo token através do link de "Esqueci a senha"',
          color: 'danger'
        }
      }

      if (response === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'A senha digitada não pode ser igual a senha atual',
          color: 'danger'
        }
      }
    },
    /**
     * Validates confirmation password
     */
    checkConfirmationPassword() {
      if (
        this.form.password &&
        this.form.password !== this.form.password_confirmation
      ) {
        this.passwordErrors.confirmation = false
      } else {
        this.passwordErrors.confirmation = null
      }
    }
  }
}
</script>

<style lang="scss">
.change-password-page {
  background-image: linear-gradient(
    to left bottom,
    #000000,
    #101010,
    #1b1b1b,
    #252525,
    #2f2f2f
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .card {
    border-left: none;
    border-bottom: none;
    border-right: none;

    .card-image {
      img {
        max-height: 80px;
      }
    }

    .form-group {
      .input-group {
        .input-group-text {
          background: $info;
          border-color: $info;
          color: $white;
        }
      }
    }
  }

  .modal {
    .modal-footer {
      display: none;
    }
  }

  .text-expired {
    color: #ef5b5b;
    font-size: 14px;
  }

  .compare-passwords {
    position: relative;
    top: -10px;
  }

  .equal-passwords {
    color: #3c9d74;
  }

  .different-passwords {
    color: #ef5b5b;
  }

  .logo-box {
    min-height: 40px;
  }
}
</style>
